import { Content } from "@angular/compiler/src/render3/r3_ast";
import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router, UrlHandlingStrategy } from "@angular/router";
import {
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDateStruct,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormControl,
  FormControlName,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ScHttpService } from "src/app/services/http/sc-http.service";
import { ServerURLS } from "src/environments/environment";
import {
  ScCommonService,
  UnitedStatesDateParserFormatter,
} from "src/app/services/common/sc-common.service";
import { ToastrService } from "ngx-toastr";
import { url } from "inspector";
import { InvoiceComponent } from "../../commons/invoice/invoice.component";
import { formatDate } from "@angular/common";
import { CustomDateParserFormatter } from "../../creditapp/view/view.component";
import { ListDataServiceService } from "src/app/services/list-data-service.service";
import { BoundElementProperty } from "@angular/compiler";
import { start } from "repl";
import { ViewPodComponent } from "../../commons/view-pod/view-pod.component";
import { HttpClient } from "@angular/common/http";
import { UploadPodComponent } from "../../commons/upload-pod/upload-pod.component";
import { ViewPodRequestComponent } from "../../commons/view-pod-request/view-pod-request.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import * as html2pdf from "html2pdf.js";
import { iif } from "rxjs";
import { LoadingService } from "src/app/services/loader.service";
@Component({
  selector: "app-child-order",
  templateUrl: "./child-order.component.html",
  styleUrls: ["./child-order.component.scss"],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: UnitedStatesDateParserFormatter,
    },
  ],
})
export class ChildOrderComponent implements OnInit {
  @ViewChild("confirmWarn", undefined) confirmWarn;
  @ViewChild("loadContent", undefined) loadContent;

  isDataLoading: any = "";
  displayData = false;
  sortBy: any;
  orders: any = [];
  orderId: any;
  temp: any = [];
  status: any = [];
  selectTab: any = 1;
  manualOrder: any = [];
  page: number = 0;
  pageSize: number = 20;
  isDesc: any = false;
  searchKeyValue: any = "";
  tabStatus: any = "";
  modelToDate: NgbDateStruct;
  modelFromDate: NgbDateStruct;
  savingToFavValue: string = "";
  allOrdersList: any;
  tabStyle: number = 1;
  popOverDataPrice: any = [];
  selectedQuoteID: any;
  selectedOrderID: any;
  selectedStatus: any;
  trackingData: any;
  collectionSize: any = 5;
  noOfPages: any;
  quoteId: any;
  quote: any;
  ordersId: any;
  orderStatus: any;
  customOrderId: any;
  displayCourier: true;
  orderid: any;
  shipperZip: any;
  consigneeZip: any;
  trackOrderId: any;
  addFevForm: FormGroup;
  quoteIdArray: any = [];
  todaysDate: any;
  minDate: any;
  pleaseDataRemove: boolean = true;
  salesRepId: string;
  pdfurl: any;
  shippingMode: string;
  shippingCountryParam: any;
  carrierId: string;
  paramsTrue: any;
  proBill: any;

  parentQuoteId: string;

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private httpService: ScHttpService,
    private commanService: ScCommonService,
    private toastService: ToastrService,

    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private listDataService: ListDataServiceService,
    private config: NgbDatepickerConfig,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private _loaderService: LoadingService
  ) {}
  isDashBoardViewEnabl: boolean = false;
  isViewingAllData: boolean = false;
  ngOnInit() {
    localStorage.setItem("_adminreferrer", "childOrders");
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllDataOfPartnerList();
    this.listDataService.removeAllDataOfQuoteHistory();
    this.listDataService.removeAllDataOfStandardChargeList();
    this.listDataService.removeAllDataOfSettingpackageManageer();
    this.listDataService.removeAllDataSettingUserManagement();
    this.listDataService.removeAllDataOfInvoiceList();
    this.listDataService.removeAllDataOfToAuditInvoiceList();
    this.listDataService.removeAllDataOfToInvoiceList();
    this.listDataService.removeAllDataOfToContractProcessingList();
    this.listDataService.removeAllSalesRepData();
    this.pleaseDataRemove = true;
    //  this.modelFromDate={year:2023,month:7,day:13}
    //  this.modelToDate={year:2023,month:1,day:13}
    let startDate = this.activatedRoute.snapshot.paramMap.get("fromDate");
    let endDate = this.activatedRoute.snapshot.paramMap.get("toDate");

    this.activatedRoute.queryParams.subscribe((params) => {
      this.paramsTrue = params;
      this.salesRepId = params["salesRepId"];
      if (this.salesRepId) {
        startDate = params["fromDate"];
        endDate = params["toDate"];
      } else if (params["status"] === "UNSUCCESSFUL") {
        this.tabStyle = 5;
        this.tabStatus = "UNSUCCESSFUL";
      } else if (params["status"] === "LTL") {
        this.tabStyle = 1;
        this.shippingMode = "LTL";
      } else if (params["status"] === "CRS") {
        this.tabStyle = 1;
        this.shippingMode = "CRS";
      } else if (params["carrierId"]) {
        this.carrierId = params["carrierId"];
      } else if (params["shippingCountry"]) {
        this.tabStyle = 1;
        this.shippingCountryParam = params["shippingCountry"];
      }
    });
    let urlMode = window.location.href.split("/");
    if (urlMode[urlMode.length - 1] === "isDashBoardView") {
      this.isViewingAllData = true;
    } else if (
      urlMode[urlMode.length - 2] === "isDashBoardView" &&
      urlMode[urlMode.length - 1] === "USD"
    ) {
      this.isDashBoardViewEnabl = true;
      this.isViewingAllData = false;
    } else if (
      urlMode[urlMode.length - 2] === "isDashBoardView" &&
      urlMode[urlMode.length - 1] === "CAD"
    ) {
      this.isDashBoardViewEnabl = true;

      this.isViewingAllData = false;
    }

    if (this.salesRepId) {
      this.isDashBoardViewEnabl = true;
      this.isViewingAllData = true;
    }
    if (startDate && endDate) {
      this.modelFromDate = {
        year: parseInt(startDate.split("-")[0]),
        month: parseInt(startDate.split("-")[1]),
        day: parseInt(startDate.split("-")[2]),
      };
      this.modelToDate = {
        year: parseInt(endDate.split("-")[0]),
        month: parseInt(endDate.split("-")[1]),
        day: parseInt(endDate.split("-")[2]),
      };
    }

    this.orders = [
      { id: 1, orderType: "All Orders" },
      { id: 2, orderType: "In-Transit Orders" },
      { id: 3, orderType: "Completed Orders" },
      { id: 4, orderType: "Scheduled Orders" },
      { id: 5, orderType: "Failed Orders" },
    ];
    this.sortBy = {
      orderId: "downOrder",
      customer: "downOrder",
      consigneeName: "downOrder",
      orderSource: "downOrder",
      pickupDate: "downOrder",
      shipper: "downOrder",

      origin: "downOrder",
      consignee: "downOrder",
      destination: "downOrder",
      totalCost: "downOrder",
      carrierName: "downOrder",
      shipmentMode: "downOrder",
    };

    this.fetchOrderList(this.page, this.pageSize);

    //  this.getOrderMetaDataById(this.quote)

    this.addFevForm = this.fb.group({
      addFev: ["", [Validators.required]],
    });

    this.config.minDate = { month: 1, day: 1, year: 1980 };
    this.config.outsideDays = "hidden";
  }
  resetFilter() {
    if (!this.isDashBoardViewEnabl) {
      if (!this.isViewingAllData) {
        this.modelFromDate = undefined;
        this.modelToDate = undefined;
        this.searchKeyValue = "";
        this.page = 0;
        this.pageSize = 20;
        this.getAllKeyofSortByArray().forEach((element, index) => {
          this.sortBy[element] = "downOrder";
        });
        this.sortParam = "";
        this.isAscending = true;
        this.listDataService.removeDataOfTabWhenClickOnResetButton(
          this.tabStyle
        );
        this.fetchOrderList(this.page, this.pageSize);
      } else {
        this.listDataService.removeAllOrderListData();
        this.route.navigateByUrl("shipcarte/orders");
      }
    }
  }
  checkDataIsPresentInServiceFile() {
    //getting the data of list from the service file to check data is already loaded or not
    let listData = this.listDataService.getAllOrderListData();
    console.log(listData);
    if (listData.tabId > 0) {
      if (listData.tabId == 1) {
        if (listData.allOrderList.dataList.length > 0) {
          this.isDataLoading = "";
          this.manualOrder = listData.allOrderList.dataList;
          this.page = listData.allOrderList.pageCount;
          this.pageSize = listData.allOrderList.pageSize;
          if (listData.allOrderList.searchkey != "") {
            this.searchKeyValue = listData.allOrderList.searchkey;
          }

          if (listData.allOrderList.fromDate && listData.allOrderList.toDate) {
            this.modelFromDate = {
              year: parseInt(listData.allOrderList.fromDate.split("-")[0]),
              month: parseInt(listData.allOrderList.fromDate.split("-")[1]),
              day: parseInt(listData.allOrderList.fromDate.split("-")[2]),
            };
            this.modelToDate = {
              year: parseInt(listData.allOrderList.toDate.split("-")[0]),
              month: parseInt(listData.allOrderList.toDate.split("-")[1]),
              day: parseInt(listData.allOrderList.toDate.split("-")[2]),
            };
          }
          this.scrollToViewMethod(
            listData.tabId,
            listData.allOrderList.selectedId
          );
        } else {
          this.searchKeyValue = "";
          this.fetchOrderList(this.page, this.pageSize);
        }
        this.getAllKeyofSortByArray().forEach((element, index) => {
          this.sortBy[element] = "downOrder";
        });
        this.isAscending = listData.allOrderList.isAscending;
        if (listData.allOrderList.sortParam != "") {
          this.sortParam = listData.allOrderList.sortParam;
          this.sortBy[listData.allOrderList.sortParam] = this.isAscending
            ? "upOrder"
            : "downOrder";
        }
      } else if (listData.tabId == 2) {
        if (listData.intransitList.dataList.length > 0) {
          this.isDataLoading = "";
          this.manualOrder = listData.intransitList.dataList;
          this.page = listData.intransitList.pageCount;
          this.pageSize = listData.intransitList.pageSize;
          if (listData.intransitList.searchkey != "") {
            this.searchKeyValue = listData.intransitList.searchkey;
          }
          if (
            listData.intransitList.fromDate &&
            listData.intransitList.toDate
          ) {
            this.modelFromDate = {
              year: parseInt(listData.intransitList.fromDate.split("-")[0]),
              month: parseInt(listData.intransitList.fromDate.split("-")[1]),
              day: parseInt(listData.intransitList.fromDate.split("-")[2]),
            };
            this.modelToDate = {
              year: parseInt(listData.intransitList.toDate.split("-")[0]),
              month: parseInt(listData.intransitList.toDate.split("-")[1]),
              day: parseInt(listData.intransitList.toDate.split("-")[2]),
            };
          }
          this.scrollToViewMethod(
            listData.tabId,
            listData.intransitList.selectedId
          );
        } else {
          this.searchKeyValue = "";
          this.fetchOrderList(this.page, this.pageSize);
        }
        this.isAscending = listData.intransitList.isAscending;
        this.getAllKeyofSortByArray().forEach((element, index) => {
          this.sortBy[element] = "downOrder";
        });
        if (listData.intransitList.sortParam != "") {
          this.sortParam = listData.intransitList.sortParam;
          this.sortBy[listData.intransitList.sortParam] = this.isAscending
            ? "upOrder"
            : "downOrder";
        }
      } else if (listData.tabId == 3) {
        if (listData.completedList.dataList.length > 0) {
          this.isDataLoading = "";
          this.manualOrder = listData.completedList.dataList;
          this.page = listData.completedList.pageCount;
          this.pageSize = listData.completedList.pageSize;
          if (listData.completedList.searchkey != "") {
            this.searchKeyValue = listData.completedList.searchkey;
          }
          if (
            listData.completedList.fromDate &&
            listData.completedList.toDate
          ) {
            this.modelFromDate = {
              year: parseInt(listData.completedList.fromDate.split("-")[0]),
              month: parseInt(listData.completedList.fromDate.split("-")[1]),
              day: parseInt(listData.completedList.fromDate.split("-")[2]),
            };
            this.modelToDate = {
              year: parseInt(listData.completedList.toDate.split("-")[0]),
              month: parseInt(listData.completedList.toDate.split("-")[1]),
              day: parseInt(listData.completedList.toDate.split("-")[2]),
            };
          }
          this.scrollToViewMethod(
            listData.tabId,
            listData.completedList.selectedId
          );
        } else {
          this.searchKeyValue = "";
          this.fetchOrderList(this.page, this.pageSize); //1
        }
        this.isAscending = listData.completedList.isAscending;
        this.getAllKeyofSortByArray().forEach((element, index) => {
          this.sortBy[element] = "downOrder";
        });
        if (listData.completedList.sortParam != "") {
          this.sortParam = listData.completedList.sortParam;
          this.sortBy[listData.completedList.sortParam] = this.isAscending
            ? "upOrder"
            : "downOrder";
        }
      } else if (listData.tabId == 4) {
        if (listData.scheduledList.dataList.length > 0) {
          this.isDataLoading = "";
          this.manualOrder = listData.scheduledList.dataList;
          this.page = listData.scheduledList.pageCount;
          this.pageSize = listData.scheduledList.pageSize;
          if (listData.completedList.searchkey != "") {
            this.searchKeyValue = listData.completedList.searchkey;
          }
          if (
            listData.scheduledList.fromDate &&
            listData.scheduledList.toDate
          ) {
            this.modelFromDate = {
              year: parseInt(listData.scheduledList.fromDate.split("-")[0]),
              month: parseInt(listData.scheduledList.fromDate.split("-")[1]),
              day: parseInt(listData.scheduledList.fromDate.split("-")[2]),
            };
            this.modelToDate = {
              year: parseInt(listData.scheduledList.toDate.split("-")[0]),
              month: parseInt(listData.scheduledList.toDate.split("-")[1]),
              day: parseInt(listData.scheduledList.toDate.split("-")[2]),
            };
          }
          this.scrollToViewMethod(
            listData.tabId,
            listData.scheduledList.selectedId
          );
        } else {
          this.searchKeyValue = "";
          this.fetchOrderList(this.page, this.pageSize);
        }
        this.isAscending = listData.scheduledList.isAscending;
        this.getAllKeyofSortByArray().forEach((element, index) => {
          this.sortBy[element] = "downOrder";
        });
        if (listData.scheduledList.sortParam != "") {
          this.sortParam = listData.scheduledList.sortParam;
          this.sortBy[listData.scheduledList.sortParam] = this.isAscending
            ? "upOrder"
            : "downOrder";
        }
      } else if (listData.tabId == 5) {
        console.log("listData", listData);
        if (listData.failedList.dataList.length > 0) {
          this.isDataLoading = "";
          this.manualOrder = listData.failedList.dataList;
          this.page = listData.failedList.pageCount;
          this.pageSize = listData.failedList.pageSize;
          if (listData.failedList.searchkey != "") {
            this.searchKeyValue = listData.failedList.searchkey;
          }
          if (listData.failedList.fromDate && listData.failedList.toDate) {
            this.modelFromDate = {
              year: parseInt(listData.failedList.fromDate.split("-")[0]),
              month: parseInt(listData.scheduledList.fromDate.split("-")[1]),
              day: parseInt(listData.scheduledList.fromDate.split("-")[2]),
            };
            this.modelToDate = {
              year: parseInt(listData.failedList.toDate.split("-")[0]),
              month: parseInt(listData.scheduledList.toDate.split("-")[1]),
              day: parseInt(listData.scheduledList.toDate.split("-")[2]),
            };
          }
          this.scrollToViewMethod(
            listData.tabId,
            listData.failedList.selectedId
          );
        } else {
          this.searchKeyValue = "";
          this.fetchOrderList(this.page, this.pageSize);
        }
        this.isAscending = listData.failedList.isAscending;
        this.getAllKeyofSortByArray().forEach((element, index) => {
          this.sortBy[element] = "downOrder";
        });
        if (listData.failedList.sortParam != "") {
          this.sortParam = listData.failedList.sortParam;
          this.sortBy[listData.failedList.sortParam] = this.isAscending
            ? "upOrder"
            : "downOrder";
        }
      }

      this.tabStyle = listData.tabId;
      if (listData.tabId == 1) {
        this.tabStatus = "";
      } else if (listData.tabId == 2) {
        this.tabStatus = "INTRANSIT";
      } else if (listData.tabId == 3) {
        this.tabStatus = "DELIVERED";
      } else if (listData.tabId == 4) {
        this.tabStatus = "NEW";
      } else if (listData.tabId == 5) {
        this.tabStatus = "UNSUCCESSFUL";
      }
    } else {
      this.fetchOrderList(this.page, this.pageSize); //1
    }
  }
  getAllKeyofSortByArray() {
    return Object.keys(this.sortBy);
  }
  scrollToViewMethod(tabId: any, selectedItemId: any) {
    setTimeout(() => {
      let element;
      if (tabId == 1) {
        element = document.getElementById(selectedItemId);
      } else if (tabId == 2) {
        element = document.getElementById(selectedItemId);
      } else if (tabId == 3) {
        element = document.getElementById(selectedItemId);
      } else if (tabId == 4) {
        element = document.getElementById(selectedItemId);
      } else if (tabId == 5) {
        element = document.getElementById(selectedItemId);
      }

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }
  onClickCreateManualOrder() {
    this.pleaseDataRemove = true;
    this.modalService.open(this.loadContent, {
      centered: true,
      backdropClass: "light-blue-backdrop",
      windowClass: "dark-modal",
      backdrop: "static",
    });
    this.route.navigateByUrl("/shipcarte/orders/newOrderEntry");
  }
  isAscending: boolean = true;
  // all order list Fetch **************************************************
  fetchOrderList(page: number, pageSize) {
    this.allDataLoaded = false;
    this.isDataLoading = "";
    this._loaderService.stringSubject.next(true);

    // Retrieve parentQuoteId and parentCustomOrderId from sessionStorage
    this.parentQuoteId = sessionStorage.getItem("parentQuoteId");
    const parentCustomOrderId = sessionStorage.getItem("parentCustomOrderId"); // Get parentCustomOrderId

    console.log("Retrieved parentQuoteId:", this.parentQuoteId);
    console.log("Retrieved parentCustomOrderId:", parentCustomOrderId);

    if (!this.parentQuoteId) {
      this.isDataLoading = "No Parent Quote ID Found!";
      this._loaderService.stringSubject.next(false);
      return;
    }

    let url =
      ServerURLS.getManualOrder.action +
      this.page +
      "/" +
      this.pageSize +
      "?parentQuoteId=" +
      this.parentQuoteId;
    console.log("Constructed URL:", url);

    let searchKey = this.searchKeyValue;
    let salesRepId = this.salesRepId;
    if (this.sortParam != "") {
      url = url + "&sortParam=" + this.sortParam;
    }

    if (this.carrierId != "" && this.carrierId !== undefined) {
      const separator = url.includes("?") ? "&" : "?";
      url = `${url}${separator}carrierId=${this.carrierId}&isDashBoardView=true`;
    }

    if (salesRepId) {
      url = url + `&salesRepId=${salesRepId}&isDashBoardView=true`;
    }

    if (this.shippingCountryParam) {
      const separator = url.includes("?") ? "&" : "?";
      url = `${url}${separator}shippingCountry=${this.shippingCountryParam}&isDashBoardView=true`;
    }

    if (searchKey.length > 0) {
      url = url + `&searchKey=${searchKey}`;
    }
    if (this.tabStatus != "") {
      url = url + `&shipmentStatus=${this.tabStatus}`;
    }
    if (this.shippingMode != null && this.shippingMode !== "") {
      url += `&isDashBoardView=true&shippingMode=${this.shippingMode}`;
    }

    if (this.modelToDate && this.modelFromDate) {
      let dates = this.commanService.dateFormatter(
        this.modelFromDate,
        this.modelToDate
      );
      let fromDate = `${dates[0].year}-${dates[0].month}-${dates[0].date}`;
      let toDate = `${dates[1].year}-${dates[1].month}-${dates[1].date}`;
      url = url + `&startDate=${fromDate}&endDate=${toDate}`;
    }

    let urlMode = window.location.href.split("/");
    if (
      (urlMode[urlMode.length - 2] == "isDashBoardView" ||
        urlMode[urlMode.length - 1] == "isDashBoardView") &&
      !salesRepId
    ) {
      url = url + `&isDashBoardView=true`;
      if (urlMode[urlMode.length - 1] === "USD") {
        url = url + "&currency=USD";
      } else if (urlMode[urlMode.length - 1] === "CAD") {
        url = url + "&currency=CAD";
      }
    }

    let promise = this.httpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        console.log(res);
        this.displayData = false;
        this.isDataLoading = "";
        this._loaderService.stringSubject.next(false);
        this.manualOrder = res.response;
        this.allOrdersList = res.response;
        if (res.response.length > 0) {
          let iter = 0;
          this.quoteIdArray = [];
          for (let i = 0; i < res["response"].length; i++) {
            const order = res["response"][i];
            // Update customOrderId to display parentCustomOrderId
            order.customOrderId = parentCustomOrderId;

            order.pickupDate = order.quoteDetails.pickupDate || "N/A";
            order.shipper = order.quoteDetails.shipperAddress.company || "N/A";
            order.origin = `${order.quoteDetails.shipperAddress.city}, ${order.quoteDetails.shipperAddress.state}`;
            order.destination = `${order.quoteDetails.consigneeAddress.city}, ${order.quoteDetails.consigneeAddress.state}`;
            order.consignee =
              order.quoteDetails.consigneeAddress.company || "N/A";
            order.consigneeName = order.quoteDetails.customerName || "N/A";
            order.carrierName = order.quoteDetails.carrierName || "N/A";
            order.partnerLogo = order.quoteDetails.carrierLogo || "N/A";
            order.customerId = order.quoteDetails.customerId || "N/A";
            order.profilePic =
              order.quoteDetails.profileImageDownloadUrl || "N/A";

            iter = iter + 1;

            if (iter == res["response"].length) {
              this.manualOrder = res["response"];
              let dataObj = {
                pageCount: this.page,
                pageSize: this.pageSize,
                dataList: this.manualOrder,
                tabId: this.tabStyle,
              };
              this.listDataService.setAllOrderListData(dataObj, this.tabStyle);
              this.collectionSize = res.totalEntries;
              this.noOfPages = res.totalPages;
              this.modalService.dismissAll();
            }
            this.quoteIdArray.push(order.quoteDetails.quoteId);
          }
        } else {
          let dataObj = {
            pageCount: this.page,
            pageSize: this.pageSize,
            dataList: [],
            tabId: this.tabStyle,
          };
          this.listDataService.setAllOrderListData(dataObj, this.tabStyle);

          this.isDataLoading = "No data Found";

          this.manualOrder = [];
          this.collectionSize = 0;
          this.noOfPages = 0;
          this.modalService.dismissAll();
        }
      })
      .catch((err) => {
        this.isDataLoading = "Something Went Wrong! Please Try Again Later";
        this.modalService.dismissAll();
      });
  }

  back() {
    this.route.navigate(["/shipcarte/orders"]);
  }
  navigateToOrderDetails(order: any, index: number) {
    this.viewQuote(
      order.quoteDetails.quoteId,
      order.customOrderId,
      order.proBill,
      order.orderId,
      order.status,
      order.consigneeName,
      order.quoteDetails.customerId,
      order.status,
      order.quoteDetails.carrierName,
      order.quoteDetails.carrierLogo,
      order.source
    );

    this.route.navigate(["/shipcarte/orders/orderDetail"]);
  }
  // end******************************************************************

  selectOrderType(tabId: any) {
    this.listDataService.setOrderListTabId(tabId);
    if (!this.isDashBoardViewEnabl && !this.isViewingAllData) {
      this.modelToDate = undefined;
      this.modelFromDate = undefined;
      this.searchKeyValue = "";
    }

    this.tabStyle = tabId;
    this.page = 0;
    this.allDataLoaded = false;
    this.sortParam = "";
    if (tabId == 1) {
      // this.searchKeyValue = '';
      this.tabStatus = "";
      this.checkDataIsPresentInServiceFile();
      // this.fetchOrderList(this.page, this.pageSize, this.searchKeyValue);
    } else if (tabId == 2) {
      this.tabStatus = "INTRANSIT";
      this.checkDataIsPresentInServiceFile();

      // this.fetchOrderList(this.page, this.pageSize, this.searchKeyValue);
    } else if (tabId == 3) {
      this.tabStatus = "DELIVERED";
      this.checkDataIsPresentInServiceFile();

      // this.fetchOrderList(this.page, this.pageSize, this.searchKeyValue);
    } else if (tabId == 4) {
      this.tabStatus = "NEW";
      // this.fetchOrderList(this.page, this.pageSize, this.searchKeyValue);
      this.checkDataIsPresentInServiceFile();
    } else if (tabId == 5) {
      this.tabStatus = "UNSUCCESSFUL";
      // this.fetchOrderList(this.page, this.pageSize, this.searchKeyValue);
      this.checkDataIsPresentInServiceFile();
    }
  }
  setValue(value: any) {
    this.savingToFavValue = value;
  }

  shipAgain(quoteId, backToQuote, orderId) {
    if (backToQuote == "On Call Order") {
      sessionStorage.setItem("editOrderQuoteId", quoteId);
      this.route.navigate(
        ["/shipcarte/orders/newOrderEntry/edit", quoteId, orderId],
        {
          queryParams: {
            shipAgain: true,
          },
        }
      );
    } else if (backToQuote == "Quoted") {
      this.route.navigate(["/shipcarte/step-one/" + quoteId]);
    }
  }

  postFevData(orderId) {
    this.modalService.open(this.loadContent, {
      centered: true,
      backdropClass: "light-blue-backdrop",
      windowClass: "dark-modal",
      backdrop: "static",
    });

    let url = ServerURLS.addFevOrder.action;
    let payload = {
      orderId: orderId,
      isFavourite: true,
      favouriteOrderName: this.savingToFavValue,
    };

    let promise = this.httpService.putHttpService(url, payload);
    promise
      .then((res) => {
        this.toastService.success("Shipment Successfully added to favorites.");

        this.modalService.dismissAll();
      })
      .catch((err) => {
        this.modalService.dismissAll();

        this.toastService.error(
          err.message ? err.message : "Something Went Wrong",
          "",
          {}
        );
      });
  }

  viewQuote(
    quoteid,
    customOrId,
    orderId,
    orderStatus,
    customerName,
    customerid,
    status,
    carrierName,
    carrierLogo,
    orderSource,
    proBill
  ) {
    ///shipcarte/orders/orderDetail
    this.pleaseDataRemove = false;

    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      proBill: proBill,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource,
    };
    this.listDataService.setSelectedOrderId(orderId, this.tabStyle);
    this.commanService.quoteId = quoteid;
    sessionStorage.setItem(
      "_adminuserInformation",
      JSON.stringify(userInforamation)
    );
    this.route.navigate(["/shipcarte/orders/orderDetail"]);
    //this.tabStatus = '';
    localStorage.setItem("_adminreferrer", "childOrders");
    //this.route.navigate(['/shipcarte/orders/orderDetail'],{queryParams: {'type':'order'}});
    this.orderid = orderId;
    this.quote = quoteid;
  }

  navigatingToOrderDetail(order: any, index: number) {
    this.viewQuote(
      order.quoteDetails.quoteId,
      order.customOrderId,
      order.orderId,
      order.status,
      order.consigneeName,
      order.quoteDetails.customerId,
      order.status,
      order.quoteDetails.carrierName,
      order.quoteDetails.carrierLogo,
      order.source,
      order.proBill
    );
    this.route.navigate(["/shipcarte/orders/orderDetail"]);
    sessionStorage.setItem("probillNo", order.proBill);
    console.log("pro no:", order.proBill);
  }

  // handleOrderClick(order: any, index: number) {
  //   this.viewQuote(
  //     order.quoteDetails.quoteId,
  //     order.customOrderId,
  //     order.orderId,
  //     order.status,
  //     order.consigneeName,
  //     order.quoteDetails.customerId,
  //     order.status,
  //     order.quoteDetails.carrierName,
  //     order.quoteDetails.carrierLogo,
  //     order.source
  //   );

  //   if (order.quoteType === "Multiple") {
  //     this.route.navigate([`/shipcarte/orders/${order.orderId}/child-orders`]);
  //   } else {
  //     this.route.navigate(["/shipcarte/orders/orderDetail"]);
  //   }
  // }

  // Moduls *************************************************************
  open(content, type, orderId) {
    this.modalService
      .open(content, { size: "sm", centered: true, windowClass: "AddfevModal" })
      .result.then(
        (result) => {
          if (this.addFevForm.valid) {
            this.modalService
              .open(this.confirmWarn, {
                size: "sm",
                centered: true,
                windowClass: "confirmModal",
              })
              .result.then(
                (result) => {
                  this.postFevData(orderId);
                },
                (reason) => {}
              );
          } else {
            this.addFevForm.markAllAsTouched();
            this.open(content, "", orderId);
          }
        },
        (reason) => {}
      );
  }
  //end module ***********************************************************
  sortParam: any = "";
  sorting(property) {
    let allKeys = Object.keys(this.sortBy);
    allKeys.forEach((element, index) => {
      if (element != property) {
        this.sortBy[element] = "downOrder";
      }
    });
    if (this.sortBy[property] == "upOrder") {
      this.sortBy[property] = "downOrder";
      this.isAscending = false;
      this.sortParam = property;
    } else {
      this.sortBy[property] = "upOrder";
      this.isAscending = true;
      this.sortParam = property;
    }
    this.listDataService.setDataOfSortValueInOrderList(
      this.isAscending,
      this.sortParam,
      this.tabStyle
    );
    this.fetchOrderList(0, (this.page + 1) * 20);
    // console.log(this.sortBy)
    // if (property === 'price') {
    //   this.manualOrder.sort(function (a, b) {
    //     if (parseFloat(a[property]) < parseFloat(b[property])) {
    //       return -1 * direction;
    //     }
    //     else if (parseFloat(a[property]) > parseFloat(b[property])) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // } else {
    //   this.manualOrder.sort(function (a, b) {
    //     if (a[property].toLowerCase() < b[property].toLowerCase()) {
    //       return -1 * direction;
    //     }
    //     else if (a[property].toLowerCase() > b[property].toLowerCase()) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }
  }

  //filters*************************************************************
  timer: any;
  setSearchKey(searchParam) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchParam.length >= 3) {
        this.listDataService.setSearchkeyValueOfOrderId(
          searchParam,
          this.tabStyle
        );
        this.page = 0;
        this.searchKeyValue = searchParam;
        this.applyFilter();
      } else if (searchParam.length === 0) {
        this.page = 0;
        this.listDataService.setSearchkeyValueOfOrderId("", this.tabStyle);
        this.searchKeyValue = "";
        this.listDataService.setDataListOfOrder(this.tabStyle);
        this.checkDataIsPresentInServiceFile();
      }
    }, 500);
  }

  applyFilter() {
    if (this.modelFromDate && this.modelToDate) {
      let startDate = Date.parse(
        this.modelFromDate.year +
          "-" +
          this.modelFromDate.month +
          "-" +
          this.modelFromDate.day
      );
      let endDate = Date.parse(
        this.modelToDate.year +
          "-" +
          this.modelToDate.month +
          "-" +
          this.modelToDate.day
      );
      if (startDate > endDate) {
        this.toastService.error("'From Date' should smaller than 'To Date'.");
        return;
      } else {
        let fromDate =
          this.modelFromDate.year +
          "-" +
          String(this.modelFromDate.month).padStart(2, "0") +
          "-" +
          String(this.modelFromDate.day).padStart(2, "0");
        let toDate =
          this.modelToDate.year +
          "-" +
          String(this.modelToDate.month).padStart(2, "0") +
          "-" +
          String(this.modelToDate.day).padStart(2, "0");
        this.listDataService.setDateFilterValueOfOrderId(
          fromDate,
          toDate,
          this.tabStyle
        );
        this.page = 0;
        this.fetchOrderList(this.page, this.pageSize);

        if (this.salesRepId) {
          const queryParams = { ...this.activatedRoute.snapshot.queryParams };
          queryParams["fromDate"] = fromDate;
          queryParams["toDate"] = toDate;

          this.route.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: "merge", // 'preserve' can also be used based on requirement
          });
        }
      }
    } else {
      this.page = 0;
      this.fetchOrderList(this.page, this.pageSize);
    }
  }
  showToastDateError() {
    this.toastService.error("Please select valid date range", "", {
      timeOut: 3000,
    });
  }

  // display popup data for quotes summery ********************************************************************************
  popOverIndex(i) {
    this._loaderService.stringSubject.next(true);
    // this.modalService.open(this.loadContent, {
    //   centered: true,
    //   backdropClass: "light-blue-backdrop",
    //   windowClass: "dark-modal",
    //   backdrop: "static",
    // });
    let url2 =
      `${ServerURLS.getCustomerOrderPriceComponent.action}?quoteId=` +
      this.manualOrder[i].quoteDetails.quoteId +
      `&customerId=${this.manualOrder[i].quoteDetails.customerId}`;
    let promise2 = this.httpService.getHttpService(url2, "");
    promise2
      .then((price) => {
        this.popOverDataPrice = [];
        this.popOverDataPrice = price;
        this.popOverDataPrice["customId"] =
          this.manualOrder[i]["quoteDetails"].customQuoteId;
        this.popOverDataPrice["shpmtModeName"] =
          this.manualOrder[i]["quoteDetails"].shipmentModeName;
        this.popOverDataPrice["shipmentModeStyleCode"] =
          this.manualOrder[i]["quoteDetails"].shipmentModeStyleCode;
        //this.popOverDataPrice['carrierName'] = this.shipments[i]['quoteDetails'].carrierName;
        var charges = [];

        if (this.popOverDataPrice["charges"]) {
          this.popOverDataPrice["charges"].forEach((item, index) => {
            for (var key of Object.keys(item)) {
              charges.push([key, item[key]]);
            }
          });
        }

        this.popOverDataPrice["price_breakUp"] = charges;
        var totalWeight = 0;
        if (this.manualOrder[i]["quoteDetails"]["items"]) {
          this.manualOrder[i]["quoteDetails"]["items"].forEach(
            (item, index) => {
              totalWeight +=
                (item.wghtUnit.toLowerCase() == "lbs"
                  ? item.totalWeight
                  : item.totalWeight / 2.2
                ).toFixed(2) * item.qty;
            }
          );
        }
        this.popOverDataPrice["weight"] = totalWeight;
        this.modalService.dismissAll();
        if (price) {
          this._loaderService.stringSubject.next(false);
        }
      })
      .catch((err) => {
        this._loaderService.stringSubject.next(false);
        this.modalService.dismissAll();
        this.toastService.error(err);
      });

    // this.modalService.dismissAll();
  }
  // popup data for delivered ************************ ************* *************************** *****************************
  showViewPodBtn: boolean = false;
  showPopupDeleivered(quoteid, orderId, orderStatus) {
    this.showViewPodBtn = false;
    this.selectedQuoteID = quoteid;
    this.selectedOrderID = orderId;
    this.selectedStatus = orderStatus;
    let url = `${ServerURLS.traceOrder.action}?orderId=` + orderId;
    let promise = this.httpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.trackingData = res;
        this.getInvoiceDetails(orderId);
      })
      .catch((err) => {});
  }

  showPopupInTransit(quoteId) {
    // this.getOrderMetaDataById(quoteId);
    let url = ServerURLS.orderDetails.action + "?quoteId=" + quoteId;
    let promise = this.httpService.getHttpService(url, "");

    promise.then((res: any) => {
      this.displayData = false;
      this.trackOrderId = res["orderId"];

      this.manualOrder.filter((element) => {
        if (element.orderId == this.trackOrderId) {
          this.shipperZip = element.quoteDetails.shipperAddress.zipCode;
          this.consigneeZip = element.quoteDetails.consigneeAddress.zipCode;
        }
      });

      let url1 =
        ServerURLS.getTrackingData.action + "?orderId=" + this.trackOrderId;
      let promise1 = this.httpService.getHttpService(url1, "");
      promise1.then((response) => {
        this.displayData = false;
        this.trackingData = response;
      });
    });
  }

  redirctToGeneralDetails(id) {
    // console.log(id)
    sessionStorage.setItem("_admincustomerId", id);
    this.commanService.customerId = id;
    sessionStorage.setItem("customerId", id);

    this.route.navigateByUrl(`shipcarte/customer/list/generalDetail/edit`);
  }

  data: any;
  formattedDate: any;
  getInvoiceDetails(orderId) {
    // var orderIdTemp ="9b1bbd5c-e21b-4182-b980-8296aa32179a";
    let promise = this.httpService.getHttpService(
      ServerURLS.getInvoiceDetailsById.action + "?orderId=" + orderId,
      { withCredentials: true }
    );
    promise.then((result) => {
      this.data = result;
      this.formattedDate = formatDate(
        this.data.orderDate,
        "dd/MM/yyyy",
        "en-US"
      );
      this.showViewPodBtn = true;
    });
  }
  open_modal_invoice(modal, showIcons) {
    if (modal == "viewinvoice") {
      const modalRef = this.modalService.open(InvoiceComponent, {
        size: "lg",
        centered: false,
        backdrop: true,
        windowClass: "my-custom-modal10",
        keyboard: true,
      });
      this.route.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.componentInstance.data = this.data;
      modalRef.componentInstance.quoteIdForEmail = this.selectedQuoteID;
      modalRef.componentInstance.showIcons = showIcons;
      modalRef.result.then((result) => {});
    }
  }

  changeStatusOfOrder(mode, value, i) {
    this.modalService.open(this.loadContent, {
      centered: true,
      backdropClass: "light-blue-backdrop",
      windowClass: "dark-modal",
      backdrop: "static",
    });
    let key = "";
    if (mode === "pickup") {
      key = "PICKEDUP";
    } else if (mode === "nopickup") {
      key = "NO_PICKUP";
    } else if (mode === "del") {
      key = "DELIVERED";
    } else if (mode === "notDel") {
      key = "NO_DELIVERED";
    }
    let key2 = value.status;
    let url =
      ServerURLS.changeStatuOfOrder.action +
      "?orderId=" +
      value.orderId +
      "&shipmentStatus=" +
      key;
    let promise = this.httpService.putHttpService(url, "");
    promise
      .then((res: any) => {
        this.toastService.success("Status Updated Successfully");
        //update data in main list when ever we change the status in any tab
        this.modalService.dismissAll();
        // if tabStyle==1
        //if mode=='pickup' then
        //remove element from scheduled list
        //refresh the intransit list

        //if mode=='nopickup' then
        //remove elemetn from intransit element
        //refresh the scheduled list

        //if mode=='del' then
        //remove element from intransit list
        //refresh the delivered list

        // if mode=='notDel' then
        //remove element from delivered list
        //refresh the intransit list

        // if tabStyle==2
        //remove element from intransit list

        //if mode=='nopickup' then
        //refresh the shceduled list

        //if mode=='del' then
        //refresh the delivered list

        // if tabStyle==3
        // if mode=='notDel' then
        //refresh the intransit list
        //remove element from deliverd list

        // if tabStyle==4
        // if mode=='pickup' then
        //refresh intransit list
        //remove e  lemetn from shecduled list
        this.manualOrder[i].status = res.data;

        if (this.tabStyle == 1) {
          if (mode == "pickup") {
            this.listDataService.removeElementFromTheListAccordingToTabId(
              4,
              value.orderId
            );
            this.listDataService.removeDataOfTabWhenClickOnResetButton(2);
          }
          if (mode == "nopickup") {
            this.listDataService.removeElementFromTheListAccordingToTabId(
              2,
              value.orderId
            );
            this.listDataService.removeDataOfTabWhenClickOnResetButton(4);
          }
          if (mode == "del") {
            this.listDataService.removeElementFromTheListAccordingToTabId(
              2,
              value.orderId
            );
            this.listDataService.removeDataOfTabWhenClickOnResetButton(3);
          }
          if (mode == "notdel") {
            this.listDataService.removeElementFromTheListAccordingToTabId(
              3,
              value.orderId
            );
            this.listDataService.removeDataOfTabWhenClickOnResetButton(2);
          }
        } else if (this.tabStyle == 2) {
          this.listDataService.removeElementFromTheListAccordingToTabId(
            2,
            value.orderId
          );
          if (mode == "nopickup") {
            this.listDataService.removeDataOfTabWhenClickOnResetButton(4);
          }
          if (mode == "del") {
            this.listDataService.removeDataOfTabWhenClickOnResetButton(3);
          }
        } else if (this.tabStyle == 3) {
          this.listDataService.removeDataOfTabWhenClickOnResetButton(2);
          this.listDataService.removeElementFromTheListAccordingToTabId(
            3,
            value.orderId
          );
        } else if (this.tabStyle == 4) {
          this.listDataService.removeDataOfTabWhenClickOnResetButton(2);
          this.listDataService.removeElementFromTheListAccordingToTabId(
            4,
            value.orderId
          );
        }
        this.listDataService.updateDataListOfOrderWhenChangeTheStatus(
          value.orderId,
          res.data,
          1
        );
      })
      .catch((err: any) => {
        this.modalService.dismissAll();
        this.toastService.error(
          "Something Went Wrong! Please try again later."
        );
      });
  }

  isLoading: boolean = false;
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false;
  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.scrollHandler(event);
    window.addEventListener("scroll", this.scrollChangeCallback, true);
  }
  allDataLoaded: any = false;
  scrollHandler(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !this.isLoading
      ) {
        this.isLoading = true;
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {
      this.showButton = true;
    }
    this.currentPosition = scroll;
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scrollChangeCallback, true);
    if (this.pleaseDataRemove) {
      this.listDataService.removeAllOrderListData();
    }
  }
  isDataLoadingWhileScroll: any = "";
  fetchListOfDataToAppend() {
    this.page = this.page + 1;

    let url =
      ServerURLS.getManualOrder.action +
      this.page +
      "/" +
      this.pageSize +
      "?parentQuoteId=" +
      this.parentQuoteId;

    if (this.sortParam != "") {
      url = url + "&sortParam=" + this.sortParam;
    }
    let searchKey = this.searchKeyValue;
    let salesRepId = this.salesRepId;
    if (salesRepId) {
      url = url + `&salesRepId=${salesRepId}&isDashBoardView=true`;
    }
    if (this.carrierId != "" && this.carrierId !== undefined) {
      const separator = url.includes("?") ? "&" : "?";
      url = `${url}${separator}carrierId=${this.carrierId}&isDashBoardView=true`;
    }

    if (searchKey.length > 0) {
      url = url + `&searchKey=${searchKey}`;
    }

    if (this.shippingCountryParam) {
      const separator = url.includes("?") ? "&" : "?";
      url = `${url}${separator}shippingCountry=${this.shippingCountryParam}&isDashBoardView=true`;
    }

    if (this.tabStatus != "") {
      url = url + `&shipmentStatus=${this.tabStatus}`;
    }
    if (this.shippingMode != null && this.shippingMode !== "") {
      url += `&isDashBoardView=true&shippingMode=${this.shippingMode}`;
    }
    if (this.modelToDate && this.modelFromDate) {
      let dates = this.commanService.dateFormatter(
        this.modelFromDate,
        this.modelToDate
      );
      let fromDate = `${dates[0].year}-${dates[0].month}-${dates[0].date}`;
      let toDate = `${dates[1].year}-${dates[1].month}-${dates[1].date}`;
      url = url + `&startDate=${fromDate}&endDate=${toDate}`;
    }
    let urlMode = window.location.href.split("/");
    if (
      (urlMode[urlMode.length - 2] == "isDashBoardView" ||
        urlMode[urlMode.length - 1] == "isDashBoardView") &&
      !salesRepId
    ) {
      url = url + `&isDashBoardView=true`;
      if (urlMode[urlMode.length - 1] === "USD") {
        url = url + "&currency=USD";
      } else if (urlMode[urlMode.length - 1] === "CAD") {
        url = url + "&currency=CAD";
      }
    }

    this.isDataLoadingWhileScroll = "Loading...";
    let promise = this.httpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.isLoading = false;
        console.log(res);

        if (res.response.length > 0) {
          let iter = 0;
          for (let i = 0; i < res["response"].length; i++) {
            res["response"][i].pickupDate = res["response"][i].quoteDetails
              .pickupDate
              ? res["response"][i].quoteDetails.pickupDate
              : "N/A";
            res["response"][i].shipper = res["response"][i].quoteDetails
              .shipperAddress.company
              ? res["response"][i].quoteDetails.shipperAddress.company
              : "N/A";
            res["response"][
              i
            ].origin = `${res["response"][i].quoteDetails.shipperAddress.city}, ${res["response"][i].quoteDetails.shipperAddress.state}`;
            res["response"][
              i
            ].destination = `${res["response"][i].quoteDetails.consigneeAddress.city}, ${res["response"][i].quoteDetails.consigneeAddress.state}`;
            res["response"][i].consignee = res["response"][i].quoteDetails
              .consigneeAddress.company
              ? res["response"][i].quoteDetails.consigneeAddress.company
              : "N/A";
            res["response"][i].consigneeName =
              `${res["response"][i].quoteDetails.customerName}`
                ? `${res["response"][i].quoteDetails.customerName} `
                : "N/A";
            res["response"][i].carrierName = res["response"][i].quoteDetails
              .carrierName
              ? res["response"][i].quoteDetails.carrierName
              : "N/A";
            res["response"][i].partnerLogo = res["response"][i].quoteDetails
              .carrierLogo
              ? res["response"][i].quoteDetails.carrierLogo
              : "N/A";
            res["response"][i].customerId = res["response"][i].quoteDetails
              .customerId
              ? res["response"][i].quoteDetails.customerId
              : "N/A";
            res["response"][i].profilePic = res["response"][i].quoteDetails
              .profileImageDownloadUrl
              ? res["response"][i].quoteDetails.profileImageDownloadUrl
              : "N/A";
            iter = iter + 1;
          }
          this.manualOrder = this.manualOrder.concat(res.response);
          this.isDataLoadingWhileScroll = "";

          let dataObj = {
            pageCount: this.page,
            pageSize: this.pageSize,
            dataList: this.manualOrder,
            tabId: this.tabStyle,
          };
          this.listDataService.setAllOrderListData(dataObj, this.tabStyle);
        } else {
          this.allDataLoaded = true;
          this.isDataLoadingWhileScroll = "";
        }
      })
      .catch((err: any) => {
        this.isLoading = false;
      });
  }
  cancelOrder(data, index) {
    let url =
      ServerURLS.cancelOrderFromList.action + "?order-id=" + data.orderId;
    let promise = this.httpService.postHttpService(url, "");
    promise
      .then((res: any) => {
        if (this.tabStyle == 1) {
          //if status=='sched then remove elemetn from sched order list
          if (data.status == "SCHED") {
            this.manualOrder[index]["status"] = "CANCELLED";
            this.listDataService.removeElementFromTheListAccordingToTabId(
              4,
              data.orderId
            );
          }
          //if status==intransit then remove element from intransit order list
          if (data.status == "INTRANSIT") {
            this.manualOrder[index]["status"] = "CANCELLED";
            this.listDataService.removeElementFromTheListAccordingToTabId(
              2,
              data.orderId
            );
          }
        } else if (this.tabStyle == 2) {
          //remove element from intransit order list
          this.manualOrder[index]["status"] = "CANCELLED";
          this.listDataService.removeElementFromTheListAccordingToTabId(
            2,
            data.orderId
          );
        } else if (this.tabStyle == 4) {
          // remoe elemetn from scheed order list
          this.manualOrder[index]["status"] = "CANCELLED";
          this.listDataService.removeElementFromTheListAccordingToTabId(
            4,
            data.orderId
          );
        }
        this.listDataService.updateDataListOfOrderWhenChangeTheStatus(
          data.orderId,
          "CANCELLED",
          1
        );
      })
      .catch((err: any) => {});
  }
  getOrderMetaDataById(
    quoteid,
    customOrId,
    orderId,
    orderStatus,
    customerName,
    customerid,
    status,
    carrierName,
    carrierLogo,
    orderSource,
    proBill
  ) {
    this.pleaseDataRemove = false;

    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      proBill: proBill,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource,
    };
    this.listDataService.setSelectedOrderId(orderId, this.tabStyle);
    this.commanService.quoteId = quoteid;
    sessionStorage.setItem(
      "_adminuserInformation",
      JSON.stringify(userInforamation)
    );
    if (orderSource == "Quoted") {
      // this.route.navigate(['shipcarte/step-one', userInforamation.quoteid]);
      this.toastService.error("You can't edit the Quoted order", "", {});
    } else {
      sessionStorage.setItem("editOrderQuoteId", quoteid);
      this.route.navigate([
        "shipcarte/bookedOrder/edit",
        userInforamation.quoteid,
        userInforamation.orderId,
      ]);
    }

    this.orderid = orderId;
    this.quote = quoteid;
  }

  open_modal_upload_pod(modal, index, quoteid) {
    if (modal === "uploadPod") {
      let orderId = this.manualOrder[index].orderId;
      let ladingNo = this.manualOrder[index].ladingNo;
      let url = ServerURLS.getPodDoc.action + "?orderId=" + orderId;

      if (ladingNo !== null && ladingNo !== undefined) {
        // Add ladingNo to the URL if it's not null
        url += "&ladingNo=" + ladingNo;
      }

      // Call the API to fetch the PDF content
      this.httpService.getHttpService(url, "").then((response: any) => {
        if (response && response.downloadURL) {
          // Open a modal to display the PDF
          this.toastService.error("Document has already uploaded");
        } else {
          const modalRef = this.modalService.open(UploadPodComponent, {
            size: "lg",
            centered: false,
            backdrop: true,
            windowClass: "my-custom-modal3",
            keyboard: true,
          });
          this.route.events.subscribe((val) => {
            modalRef.close();
          });
          // Pass the PDF content to the modal component
          modalRef.componentInstance.uploadOrderId =
            this.manualOrder[index].orderId;
        }
      });
    }
  }

  // open_modal_view_pod(modal, orderInfo) {
  //   if (modal === 'viewPod') {
  //     let url = ServerURLS.getPodDoc.action + '?orderId=' + orderInfo.orderId + (orderInfo.ladingNo ? '&ladingNo='+orderInfo.ladingNo : '');

  //     // Call the API to fetch the PDF content
  //     this.httpService.getHttpService(url, '').then((response: any) => {
  //       if (response && response.downloadURL) {
  //         // Open a modal to display the PDF
  //         const modalRef = this.modalService.open(ViewPodRequestComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'my-custom-modal12', keyboard: true });
  //         // Pass the PDF content to the modal component
  //         modalRef.componentInstance.pdfurl = response.downloadURL;
  //       } else {
  //         this.toastService.error(response.message);
  //       }
  //     });
  //   }
  // }

  open_modal_view_pod(modal, orderInfo) {
    if (modal === "viewPod") {
      let url =
        ServerURLS.getPodDoc.action +
        "?orderId=" +
        orderInfo.orderId +
        (orderInfo.ladingNo ? "&ladingNo=" + orderInfo.ladingNo : "");

      // Call the API to fetch the file content
      this.httpService.getHttpService(url, "").then((response: any) => {
        if (response && response.downloadURL) {
          // Extract file extension from the URL
          const fileExtension = response.downloadURL.split(".").pop();

          // List of supported file extensions for preview
          const supportedFileExtensions = ["pdf", "png", "jpg", "jpeg", "gif"]; // Add more extensions as needed

          // Check if the file extension is in the list of supported extensions
          if (supportedFileExtensions.includes(fileExtension.toLowerCase())) {
            // Open a modal to display the file
            const modalRef = this.modalService.open(ViewPodRequestComponent, {
              size: "lg",
              centered: false,
              backdrop: true,
              windowClass: "my-custom-modal12",
              keyboard: true,
            });
            // Pass the file content to the modal component
            modalRef.componentInstance.pdfurl = response.downloadURL;
          } else {
            // Display an error message if the file type is not supported
            this.toastService.error("We are unable to preview this POD file.");
          }
        } else {
          this.toastService.error(response.message);
        }
      });
    }
  }
  // downloadFile(url: string): void {
  //   this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'downloaded_file.pdf'; // You can set the desired filename here
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   });
  // }

  open_modal_pod(modal, index, quoteid) {
    // if (this.manualOrder && this.manualOrder[index] && this.manualOrder[index].quoteDetails && this.manualOrder[index].quoteDetails.shipmentModeName === 'LTL') {
    if (modal === "createPod") {
      const userInforamation = {
        quoteid: quoteid,
      };
      this.commanService.quoteId = quoteid;
      sessionStorage.setItem(
        "_adminuserInformation",
        JSON.stringify(userInforamation)
      );
      const modalRef = this.modalService.open(ViewPodComponent, {
        size: "lg",
        centered: false,
        backdrop: true,
        windowClass: "my-custom-modal12",
        keyboard: true,
      });

      this.route.events.subscribe((val) => {
        modalRef.close();
      });

      modalRef.result.then((result) => {
        // Check if the result is not null or undefined
        if (result) {
          // Convert the content of ViewPodComponent to PDF
          const element = document.getElementById("pdfConverter"); // replace with the actual id
          if (element) {
            const options = {
              margin: 10,
              filename: "pod_document.pdf",
              image: { type: "jpeg", quality: 0.98 },
              html2canvas: { scale: 2 },
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            };

            html2pdf()
              .from(element)
              .set(options)
              .outputPdf()
              .then((pdf) => {
                // Do something with the generated PDF, e.g., save or display it
                // You can save the PDF or display it as needed
                // For example, to open it in a new tab:
                const blob = new Blob([pdf], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
              });
          }
        }
      });
    }
    // }
    // else{
    //   this.toastService.error('It is applicable only for LTL')
    // }
  }
}
